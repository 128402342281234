import './RecruitmentStudySchedules.less'

import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { Button } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/de'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/it'
import 'dayjs/locale/ja'
import 'dayjs/locale/pl'
import 'dayjs/locale/pt'
import 'dayjs/locale/zh-cn'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { Schedule, fetchSchedules as fetchSchedulesRequest } from '../../../../requests'
import { localeFromPath } from '../../../../utils'
import { DatacBox, DatacMessage } from '../../../common'
import { RecruitmentStudyDetailsLayout, RecruitmentStudyDetailsTab } from '../RecruitmentStudyDetailsLayout'
import { useRecruitmentStudyDetailsStore } from '../RecruitmentStudyDetailsStore'
import { AppointmentSchedule } from './AppointmentSchedule'
import { VisitList } from './VisitList'

dayjs.updateLocale(localeFromPath(), { weekStart: 1 })

enum ScheduleTab {
  Appointment = 'appointment',
  Visits = 'visits'
}

export const RecruitmentStudySchedules: React.FC<RouteComponentProps> = () => {
  const [currentTab, setCurrentTab] = useState(ScheduleTab.Appointment)
  const intlSchedules = useScopedIntl('recruitment.study.schedules')
  const intl = useScopedIntl('')
  const [schedules, setSchedules] = useState<Schedule[]>([])
  const { study } = useRecruitmentStudyDetailsStore()
  const [isFetchingSchedules, setIsFetchingSchedules] = useState(true)
  const [isSettingUpSchedule, setIsSettingUpSchedule] = useState(false)

  useEffect(() => {
    if (!study?.id) return

    fetchSchedules()
  }, [study])

  const fetchSchedules = () => {
    setIsFetchingSchedules(true)
    fetchSchedulesRequest(
      { studyId: study.id },
      {
        onSuccess: schedules => {
          setSchedules(schedules)
          setIsFetchingSchedules(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingSchedules(false)
        }
      }
    )
  }

  return (
    <RecruitmentStudyDetailsLayout currentTab={RecruitmentStudyDetailsTab.Schedules}>
      <DatacBox className="recruitment-study-schedules">
        <div className="recruitment-study-schedules__header">
          <div className="recruitment-study-schedules__header__menu">
            {Object.values(ScheduleTab).map((tab, index) => (
              <div
                key={index}
                className={`recruitment-study-schedules__header__menu__item ${currentTab === tab ? 'active' : ''}`}
                onClick={() => setCurrentTab(tab)}
                role="tab"
                tabIndex={index}
              >
                {intlSchedules(tab)}
              </div>
            ))}
          </div>
          {!isFetchingSchedules && !!schedules.length && currentTab === ScheduleTab.Appointment && (
            <Button type="text" onClick={() => setIsSettingUpSchedule(true)}>
              {intlSchedules('create')}
            </Button>
          )}
        </div>
        <div className="recruitment-study-schedules__content">
          {currentTab === ScheduleTab.Visits && <VisitList schedules={schedules} />}
          {currentTab === ScheduleTab.Appointment && (
            <AppointmentSchedule
              schedules={schedules}
              isFetchingSchedules={isFetchingSchedules}
              isSettingUpSchedule={isSettingUpSchedule}
              setIsSettingUpSchedule={setIsSettingUpSchedule}
              fetchSchedules={() => fetchSchedules()}
            />
          )}
        </div>
      </DatacBox>
    </RecruitmentStudyDetailsLayout>
  )
}
