import './ParticipantsDashboard.less'

import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { Tabs } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import {
  RecruitmentVariable,
  SourcedRecruitmentVariable,
  TableColumnVariableSource,
  TableVariable,
  fetchRecruitmentVariables,
  fetchSubjectRepositoryVariables,
  updateRecruitmentStudy
} from '../../../../requests'
import { DatacIcon, DatacIconName, DatacMessage, DatacTableColumnPicker } from '../../../common'
import { RecruitmentProfileQuickView } from '../RecruitmentProfile/RecruitmentProfileQuickView'
import { RecruitmentStudyDetailsLayout, RecruitmentStudyDetailsTab } from '../RecruitmentStudyDetailsLayout'
import { ShowFiltersValue, useRecruitmentStudyDetailsStore } from '../RecruitmentStudyDetailsStore'
import { ParticipantsDashboardKpis } from './ParticipantsDashboardKpis'
import { ParticipantsTable } from './ParticipantsTable'

/* todo:
export interface ViewTabData {
  name: string
  filters: any[]
} */

interface Props extends RouteComponentProps {
  isLoadingStudy: boolean
}
export const ParticipantsDashboard: React.FC<Props> = ({ isLoadingStudy }) => {
  const {
    study,
    reloadParticipantsTable,
    centers,
    triggerReloadParticipantsTable,
    allVariables,
    setSubjectVariables,
    setRecruitmentVariables,
    showFilters,
    toggleShowFilters
  } = useRecruitmentStudyDetailsStore()
  const intlParticipants = useScopedIntl('recruitment.study.participants')
  const intl = useScopedIntl('')
  const [isFetchingRecruitmentVariables, setIsFetchingRecruitmentVariables] = useState(false)
  const [isFetchingSubjectVariables, setIsFetchingSubjectVariables] = useState(false)
  // todo: const [savedViews, setSavedViews] = useState<ViewTabData[]>([])
  const [allParticipantsCount, setAllParticipantsCount] = useState<number>(null)
  const { setStudy } = useRecruitmentStudyDetailsStore()

  useEffect(() => {
    if (!study || !centers?.length) return

    updateAllVariablesList()
  }, [study, centers, reloadParticipantsTable])

  const tabItems = useMemo((): React.ComponentProps<typeof Tabs>['items'] => {
    if (isLoadingStudy) return []
    return [
      {
        key: 'all',
        label: (
          <>
            <span className="participants-dashboard__tab-label">{intlParticipants('all')}</span>
            {allParticipantsCount !== null ? (
              <span className="participants-dashboard__tab-count">{allParticipantsCount}</span>
            ) : null}
          </>
        ),
        destroyInactiveTabPane: false,
        icon: <DatacIcon name="users" raw />,
        children: (
          <ParticipantsTable isLoadingStudy={isLoadingStudy} setAllParticipantsCount={setAllParticipantsCount} />
        )
      }
      // todo: add saved tabs here
    ]
  }, [isLoadingStudy, /* savedViews, */ allParticipantsCount])

  const updateAllVariablesList = () => {
    setIsFetchingRecruitmentVariables(true)
    fetchRecruitmentVariables(
      { studyId: study.id },
      {
        onSuccess: variables => {
          setRecruitmentVariables(variables)
          setIsFetchingRecruitmentVariables(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingRecruitmentVariables(false)
        }
      }
    )
    setIsFetchingSubjectVariables(true)
    fetchSubjectRepositoryVariables({
      onSuccess: variables => {
        setSubjectVariables(variables)
        setIsFetchingSubjectVariables(false)
      },
      onRequestError: code => {
        DatacMessage.genericError(intl, code)
        setIsFetchingSubjectVariables(false)
      }
    })
  }

  const variablesLoading = isFetchingRecruitmentVariables || isFetchingSubjectVariables

  const buttonsContent = (
    <div className="participants-dashboard__buttons">
      {['search', 'filter'].map(val => (
        <button
          key={val}
          type="button"
          className={classNames('raw participants-dashboard__buttons__button', {
            'participants-dashboard__buttons__button--active': showFilters === val
          })}
          onClick={() => toggleShowFilters(val as ShowFiltersValue)}
        >
          <DatacIcon name={val as DatacIconName} />
        </button>
      ))}
      <DatacTableColumnPicker
        type="compact"
        allColumns={allVariables}
        columns={((variablesLoading ? [] : study?.tableVariables || []) as SourcedRecruitmentVariable[]).map(
          variable => {
            const title =
              variable.source === TableColumnVariableSource.RecruitmentSurvey
                ? allVariables[TableColumnVariableSource.RecruitmentSurvey].find(
                    (tableVariable: RecruitmentVariable) => tableVariable.variable === variable.variable
                  )?.title || ''
                : allVariables[TableColumnVariableSource.SubjectDatabase].find(
                    (tableVariable: TableVariable) => tableVariable.variable === variable.variable
                  )?.title || ''
            return {
              source: variable.source,
              label: variable.variable,
              value: variable.variable,
              sublabel: title
            }
          }
        )}
        updateColumns={columns => {
          updateRecruitmentStudy(
            {
              tableVariables: columns.map(tableColumn => ({
                variable: tableColumn.value,
                source: tableColumn.source
              })),
              studyId: study.id
            },
            {
              onSuccess: study => {
                setStudy(study)
                triggerReloadParticipantsTable()
              },
              onRequestError: code => DatacMessage.genericError(intl, code)
            }
          )
        }}
        isLoading={variablesLoading}
        isPopover
      />
    </div>
  )

  return (
    <RecruitmentStudyDetailsLayout currentTab={RecruitmentStudyDetailsTab.Participants}>
      {!isLoadingStudy && (
        <>
          <ParticipantsDashboardKpis />
          <div className="participants-dashboard">
            <Tabs tabBarExtraContent={buttonsContent} items={tabItems} destroyInactiveTabPane tabBarGutter={0} />
          </div>
          <RecruitmentProfileQuickView onClose={triggerReloadParticipantsTable} />
        </>
      )}
    </RecruitmentStudyDetailsLayout>
  )
}
