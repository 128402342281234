import './ApplicationStatus.less'

import React from 'react'

import { useScopedIntl } from '../../../../../../hooks'
import { ParticipantStatus } from '../../../../../../requests'
import { DatacInformationMessage, DatacTitle } from '../../../../../common'
import { ConfirmationSelect } from '../../../ConfirmationSelect'

interface Props {
  possibleTransitions: ParticipantStatus[]
  selectedStatus: ParticipantStatus
  onStatusChange: (status: ParticipantStatus) => void
}

export const ApplicationStatus: React.FC<Props> = ({ possibleTransitions, selectedStatus, onStatusChange }) => {
  const intlConfirmation = useScopedIntl('recruitment.study.schedules.table.confirmation')

  return (
    <div className="candidates-table-confirmation-application-status">
      <DatacTitle size="medium">{intlConfirmation('select_application_status')}</DatacTitle>
      <div className="candidates-table-confirmation-application-status__select">
        {possibleTransitions?.map(status => (
          <ConfirmationSelect
            status={status}
            onSelect={() => onStatusChange(status)}
            isSelected={selectedStatus === status}
            key={status}
          />
        ))}
      </div>
      {!possibleTransitions?.length && (
        <DatacInformationMessage
          type="error"
          title={intlConfirmation('no_possible_statuses.title')}
          message={intlConfirmation('no_possible_statuses.message')}
        />
      )}
      {selectedStatus === ParticipantStatus.Excluded && (
        <DatacInformationMessage
          type="error"
          title={intlConfirmation('rejected_warning.title')}
          message={intlConfirmation('rejected_warning.message')}
        />
      )}
      {selectedStatus === ParticipantStatus.Disqualified && (
        <DatacInformationMessage
          type="error"
          title={intlConfirmation('disqualified_warning.title')}
          message={intlConfirmation('disqualified_warning.message')}
        />
      )}
    </div>
  )
}
