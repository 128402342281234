import './RecruitmentStudyCard.less'

import { Card, Popover, Progress } from 'antd'
import classNames from 'classnames'
import { navigate } from 'gatsby-plugin-react-intl'
import React from 'react'

import { useScopedIntl } from '../../../../hooks'
import { CenterData, RecruitmentStudy, getRecruitmentStudyProgress } from '../../../../requests'
import { routes } from '../../../../routes'
import { localeFromPath } from '../../../../utils'
import { DatacIcon, DatacStudyStatusTag, DatacTooltip } from '../../../common'

const Record: React.FC<{ label: string; value: string | React.ReactNode }> = ({ label, value }) => (
  <div className="recruitment-study-card__info-record">
    <span className="recruitment-study-card__record-label">{label}</span>
    <span className="recruitment-study-card__record-value">{value}</span>
  </div>
)

interface Props {
  study: RecruitmentStudy
  centers: CenterData[]
  index: number
}

export const RecruitmentStudyCard: React.FC<Props> = ({ study, centers, index }) => {
  const intlCard = useScopedIntl('recruitment.study_card')

  const targetDate = study.targetDate ? study.targetDate.locale(localeFromPath()).format('DD MMM YYYY') : '-'

  const renderCenters = () => {
    if (!centers) return '-'

    const centersNamesList = centers.map(({ abbreviation, name }) => (
      <div className="recruitment-study-card__center" key={abbreviation}>
        {abbreviation} ({name})
      </div>
    ))

    if (centersNamesList.length < 3) return <>{centersNamesList}</>

    const popoverContent = <div className="recruitment-study-card__centers">{centersNamesList}</div>

    return (
      <>
        <span className="recruitment-study-card__first_centers">{centersNamesList.slice(0, 2)}</span>
        <Popover content={popoverContent} placement="bottomLeft" trigger="hover">
          + {centers.length - 2} {centers.length > 3 ? intlCard('others') : intlCard('other')}
        </Popover>
      </>
    )
  }

  const { progressCount, progressPercent } = getRecruitmentStudyProgress(study)

  const progressRecord = (type: 'candidates' | 'qualified') => (
    <div className="recruitment-study-card__progress-text__record">
      <div
        className={classNames(
          'recruitment-study-card__progress-text__record__indicator',
          `recruitment-study-card__progress-text__record__indicator--${type}`
        )}
      />
      <span className="recruitment-study-card__progress-text__record__number">
        {/* todo: update with proper data when refined */}
        {type === 'candidates' ? 0 : progressCount}
      </span>
      <span className="recruitment-study-card__progress-text__record__label">
        {type === 'candidates' ? intlCard('candidates') : `| ${study.target} ${intlCard('qualified')}`}
      </span>
    </div>
  )

  return (
    <Card
      title={
        <div className="recruitment-study-card__header">
          <span className="recruitment-study-card__reference">{study.reference}</span>
          <div className="recruitment-study-card__status">
            <DatacStudyStatusTag status={study.status} />
          </div>
        </div>
      }
      className="recruitment-study-card"
      onClick={() => navigate(routes.recruitmentStudy(study.id))}
      role="button"
      tabIndex={index}
      key={index}
    >
      <>
        <div className="recruitment-study-card__progress">
          <div className="recruitment-study-card__progress-text">
            <div className="recruitment-study-card__progress-text__label">{intlCard('progress')}</div>
            {progressRecord('qualified')}
          </div>
          <div className="recruitment-study-card__graph">
            <Progress
              type="circle"
              percent={progressPercent}
              strokeWidth={4}
              format={percent => `${Math.floor(percent)}%`}
            />
          </div>
        </div>
        <div className="recruitment-study-card__info">
          <div className="recruitment-study-card__row">
            <div className="recruitment-study-card__info-item">
              <DatacIcon name="calendar" type="blue" size="big" />
              <Record label={intlCard('target_date')} value={targetDate} />
            </div>
            <div className="recruitment-study-card__info-item">
              <DatacIcon name="user" type="blue" size="big" />
              <Record label={intlCard('recruiter')} value={study.recruiterName} />
            </div>
          </div>
          <div className="recruitment-study-card__info-item">
            <DatacIcon name="clipboard" type="blue" size="big" />
            <span className="datac-link recruitment-study-card__study-name">
              <DatacTooltip title={study.name}>{study.name}</DatacTooltip>
            </span>
          </div>
          <div className="recruitment-study-card__info-item">
            <DatacIcon name="disc" type="blue" size="big" />
            <Record label={intlCard('centers')} value={renderCenters()} />
          </div>
        </div>
      </>
    </Card>
  )
}
