import './ConfirmationSelect.less'

import classNames from 'classnames'
import React from 'react'

import CalendarCancelled from '../../../../assets/images/calendar-cancelled.svg'
import CalendarCompleted from '../../../../assets/images/calendar-completed.svg'
import CalendarNotDone from '../../../../assets/images/calendar-not-done.svg'
import ParticipantCompleted from '../../../../assets/images/participant_completed.svg'
import ParticipantContacted from '../../../../assets/images/participant_contacted.svg'
import ParticipantDisqualified from '../../../../assets/images/participant_disqualified.svg'
import ParticipantEnrolled from '../../../../assets/images/participant_enrolled.svg'
import ParticipantExcluded from '../../../../assets/images/participant_excluded.svg'
import ParticipantInterested from '../../../../assets/images/participant_interested.svg'
import ParticipantNotInterested from '../../../../assets/images/participant_not_interested.svg'
import ParticipantProspect from '../../../../assets/images/participant_prospect.svg'
import ParticipantQualified from '../../../../assets/images/participant_qualified.svg'
import { useScopedIntl } from '../../../../hooks'
import { BookedVisitStatus, ParticipantStatus } from '../../../../requests'

type SelectedStatus = BookedVisitStatus | ParticipantStatus
interface Props {
  status: SelectedStatus
  onSelect: () => void
  hasDescription?: boolean
  isSelected?: boolean
  isVisitStatus?: boolean
}

export const ConfirmationSelect: React.FC<Props> = ({
  status,
  onSelect,
  hasDescription,
  isVisitStatus,
  isSelected
}) => {
  const intlConfirmation = useScopedIntl('recruitment.study.schedules.table.confirmation')
  const intlStatus = useScopedIntl('status')

  const getImage = () => {
    switch (status) {
      case BookedVisitStatus.Scheduled:
      case BookedVisitStatus.Completed:
        return isVisitStatus ? <CalendarCompleted /> : <ParticipantCompleted />
      case BookedVisitStatus.NotDone:
        return <CalendarNotDone />
      case BookedVisitStatus.Cancelled:
        return <CalendarCancelled />
      case ParticipantStatus.Prospect:
        return <ParticipantProspect />
      case ParticipantStatus.Contacted:
        return <ParticipantContacted />
      case ParticipantStatus.Interested:
        return <ParticipantInterested />
      case ParticipantStatus.NotInterested:
        return <ParticipantNotInterested />
      case ParticipantStatus.Qualified:
        return <ParticipantQualified />
      case ParticipantStatus.Disqualified:
        return <ParticipantDisqualified />
      case ParticipantStatus.Enrolled:
        return <ParticipantEnrolled />
      case ParticipantStatus.Excluded:
        return <ParticipantExcluded />
      default:
        return null
    }
  }

  return (
    <div
      role="button"
      tabIndex={0}
      className={classNames('recruitment-confirmation-select', { active: isSelected })}
      onClick={onSelect}
    >
      {getImage()}
      <div className="recruitment-confirmation-select__name">{intlStatus(status.toLowerCase())}</div>
      {hasDescription && intlConfirmation(`status_description.${status.toLowerCase()}`)}
    </div>
  )
}
