import './ParticipantsDashboardKpis.less'

import React from 'react'

import { ParticipantsDashboardFunnel } from './ParticipantsDashboardFunnel'
import { ParticipantsDashboardTarget } from './ParticipantsDashboardTarget'

export const ParticipantsDashboardKpis: React.FC = () => {
  return (
    <div className="participants-dashboard-kpis">
      <ParticipantsDashboardTarget />
      <ParticipantsDashboardFunnel />
    </div>
  )
}
