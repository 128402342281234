import { Progress } from 'antd'
import React from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { getRecruitmentStudyProgress } from '../../../../../requests'
import { DatacIcon, DatacTitle, DatacTooltip } from '../../../../common'
import { useRecruitmentStudyDetailsStore } from '../../RecruitmentStudyDetailsStore'

export const ParticipantsDashboardTarget: React.FC = () => {
  const intlTarget = useScopedIntl('recruitment.study.target')
  const { study } = useRecruitmentStudyDetailsStore()

  const { progressCount, progressPercent } = getRecruitmentStudyProgress(study)

  return (
    <div className="participants-dashboard-target">
      <div className="participants-dashboard-target__top">
        <DatacTitle type="h3">
          {intlTarget('title')}
          <DatacTooltip title={intlTarget('help')} placement="top">
            <div className="participants-dashboard-target__help">
              <DatacIcon raw name="info" />
            </div>
          </DatacTooltip>
        </DatacTitle>
      </div>
      <div className="participants-dashboard-target__bottom">
        <div className="participants-dashboard-target__counter">
          <strong>{progressCount}</strong>
          <small>/ {study.target}</small>
        </div>
        <div className="participants-dashboard-target__progress">
          <Progress percent={progressPercent} showInfo={false} />
        </div>
        <div className="participants-dashboard-target__progress-legend">
          <div>
            {intlTarget('target')} <span>{progressPercent}%</span>
          </div>
          <div>
            {intlTarget('left')}{' '}
            <strong>
              {study.target - progressCount} ({100 - progressPercent}%)
            </strong>
          </div>
        </div>
      </div>
    </div>
  )
}
