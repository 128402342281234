import './RecruitmentProfileNotes.less'

import { Button, Input } from 'antd'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { AclAction, AclFeature, updateParticipantNote } from '../../../../../requests'
import { maxEditorCharactersLength } from '../../../../../validation'
import { UserContext } from '../../../../auth'
import { DatacBox, DatacIcon, DatacMessage } from '../../../../common'
import { useRecruitmentStudyDetailsStore } from '../../RecruitmentStudyDetailsStore'

export const RecruitmentProfileNotes: React.FC = () => {
  const intl = useScopedIntl('')
  const intlProfile = useScopedIntl('recruitment.study.profile')
  const { participantToView, study, updateParticipant } = useRecruitmentStudyDetailsStore()
  const [currentNote, setCurrentNote] = useState('')
  const [editedNote, setEditedNote] = useState('')
  const [isEditing, setIsEditing] = useState(false)
  const { user } = useContext(UserContext)

  useEffect(() => {
    if (!participantToView) return

    setCurrentNote(participantToView.note)
    setEditedNote(participantToView.note)
  }, [participantToView.id])

  const onCancel = () => {
    setIsEditing(false)
    setEditedNote(currentNote)
  }

  const onSubmit = () => {
    updateParticipantNote(
      { participantId: participantToView.id, note: editedNote, studyId: study.id },
      {
        onSuccess: () => {
          updateParticipant({ ...participantToView, note: editedNote })
          setCurrentNote(editedNote)
          setIsEditing(false)
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const canOpenEditMode = user.canDo(AclFeature.Recruitment)(AclAction.Edit) && !isEditing

  return (
    !!participantToView && (
      <DatacBox className="recruitment-profile-notes">
        <div className="recruitment-profile-notes__header">
          <DatacIcon raw name="fileText" />
          <div className="recruitment-profile-notes__header__title">{intlProfile('notes')}</div>
          {!currentNote && canOpenEditMode && (
            <Button type="text" className="recruitment-profile-header__link" onClick={() => setIsEditing(true)}>
              {intlProfile('add_note')}
            </Button>
          )}
          {!!currentNote && canOpenEditMode && <DatacIcon name="pencil" onClick={() => setIsEditing(true)} />}
          {isEditing && (
            <>
              <Button type="default" onClick={onCancel}>
                {intl('common.cancel')}
              </Button>
              <Button type="primary" onClick={onSubmit}>
                {intl('common.save')}
              </Button>
            </>
          )}
        </div>
        <div className="recruitment-profile-notes__body">
          {isEditing ? (
            <>
              <div className="recruitment-profile-notes__body__label">{intlProfile('note.label')}</div>
              <Input.TextArea
                rows={4}
                value={editedNote}
                onChange={e => setEditedNote(e.currentTarget.value)}
                maxLength={maxEditorCharactersLength}
                placeholder={intlProfile('note.placeholder')}
              />
            </>
          ) : (
            <div className="recruitment-profile-notes__body__text">{editedNote}</div>
          )}
        </div>
      </DatacBox>
    )
  )
}
