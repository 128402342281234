import { RouteComponentProps, Router } from '@gatsbyjs/reach-router'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import { AclAction, AclFeature, Feature, SorterOrder, fetchCenters, fetchRecruitmentStudy } from '../../../requests'
import { LocalStorageKey } from '../../../utils'
import { RedirectNoAccessWrapper } from '../../RedirectNoAccessWrapper'
import { UserContext } from '../../auth'
import { DatacMessage } from '../../common'
import { ParticipantsDashboard } from './ParticipantsDashboard'
import { RecruitmentPayments } from './RecruitmentPayments'
import { RecruitmentStudyBuilderContent } from './RecruitmentStudyBuilderContent'
import { useRecruitmentStudyDetailsStore } from './RecruitmentStudyDetailsStore'
import { RecruitmentStudySchedules } from './RecruitmentStudySchedules'
import { RecruitmentStudySettings } from './RecruitmentStudySettings'

interface Props extends RouteComponentProps {
  studyId?: string
}

export const RecruitmentStudyDetailsContent: React.FC<Props> = ({ studyId }) => {
  const { user } = useContext(UserContext)
  const intl = useScopedIntl('')
  const { setStudy, setCenters, clearStudyDetails, reloadKpis } = useRecruitmentStudyDetailsStore()
  const [isFetchingStudy, setIsFetchingStudy] = useState(true)

  useEffect(() => {
    if (user.shouldShowNextReleaseInfo()) {
      DatacMessage.nextReleaseInfo(intl, user.getNextRelease(), user.closeNextReleaseNotes, user.language)
    }
  }, [user])

  useEffect(() => {
    clearStudyDetails()
    setIsFetchingStudy(true)

    if (!studyId) return
    localStorage.setItem(LocalStorageKey.LastFeature, Feature.Recruitment)
    localStorage.setItem(LocalStorageKey.LastStudy, studyId)

    fetchCenters(
      { options: { sorter: { field: 'abbreviation', order: SorterOrder.Ascend } } },
      {
        onSuccess: ({ centers }) => setCenters(centers),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }, [studyId])

  useEffect(() => {
    if (!studyId) return
    // KPIs need to be updated and we are taking them from the study
    fetchStudy()
  }, [reloadKpis])

  const fetchStudy = () => {
    fetchRecruitmentStudy(
      { studyId },
      {
        onSuccess: study => {
          setStudy(study)
          setIsFetchingStudy(false)
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  return (
    <Router>
      <RedirectNoAccessWrapper hasNoAccess={!user.isRecruitmentEnabled} path="/">
        <ParticipantsDashboard path="/" isLoadingStudy={isFetchingStudy} />
      </RedirectNoAccessWrapper>
      <RedirectNoAccessWrapper hasNoAccess={!user.isRecruitmentEnabled} path="settings">
        <RecruitmentStudySettings path="*" />
      </RedirectNoAccessWrapper>
      <RedirectNoAccessWrapper hasNoAccess={!user.isRecruitmentEnabled} path="schedules">
        <RecruitmentStudySchedules path="*" />
      </RedirectNoAccessWrapper>
      <RedirectNoAccessWrapper
        hasNoAccess={!user.isRecruitmentEnabled || !user.isPaymentsEnabled || !user.canAccess(AclFeature.Payments)}
        path="payments"
      >
        <RecruitmentPayments path="*" isLoadingStudy={isFetchingStudy} />
      </RedirectNoAccessWrapper>
      <RedirectNoAccessWrapper
        hasNoAccess={!user.canDo(AclFeature.RecruitmentBuilder)(AclAction.Edit) || !user.isRecruitmentEnabled}
        path="builder/*"
      >
        <RecruitmentStudyBuilderContent path="builder/*" />
      </RedirectNoAccessWrapper>
    </Router>
  )
}
