import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { Collapse } from 'antd'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import {
  AclAction,
  AclFeature,
  CenterData,
  Participant,
  ParticipantsSorter,
  SelectionKeys,
  SorterOrder,
  SourcedRecruitmentVariable,
  TableColumnVariableSource,
  fetchParticipants
} from '../../../../requests'
import { TableRecord, createTableRecords } from '../../../../utils'
import { UserContext } from '../../../auth'
import {
  DatacBulkActionsBar,
  DatacMessage,
  DatacTable,
  DatacTableSearchAndFilters,
  rowClickNoPropagateClasses
} from '../../../common'
import { RecruitmentProfileQuickView } from '../RecruitmentProfile/RecruitmentProfileQuickView'
import { useRecruitmentStudyDetailsStore } from '../RecruitmentStudyDetailsStore'
import { ParticipantsExport } from './ParticipantsExport'
import { getListColumns, searchAndFilterOptions } from './ParticipantsTableConfig'
import { ParticipantsTableConfirmation } from './ParticipantsTableConfirmation'

const pageSize = 25

interface Props extends RouteComponentProps {
  isLoadingStudy: boolean
  setAllParticipantsCount: (count: number) => void
}
export const ParticipantsTable: React.FC<Props> = ({ isLoadingStudy, setAllParticipantsCount }) => {
  const {
    study,
    reloadParticipantsTable,
    participantsTableOptions,
    setParticipantsTableOptions,
    participants,
    setParticipants,
    setParticipantToView,
    centers,
    filters,
    setFilters,
    triggerReloadKpis,
    allVariables,
    showFilters
  } = useRecruitmentStudyDetailsStore()
  const intlParticipants = useScopedIntl('recruitment.study.participants')
  const intl = useScopedIntl('')
  const [currentPage, setCurrentPage] = useState(1)
  const [countAll, setCountAll] = useState(0)
  const [sorter, setSorter] = useState(participantsTableOptions.sorter)
  const [search, setSearch] = useState(participantsTableOptions.search)
  const [isFetchingParticipants, setIsFetchingParticipants] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [selectedParticipants, setSelectedParticipants] = useState<SelectionKeys>([])
  const [isStatusModalOpened, setIsStatusModalOpened] = useState(false)
  const [isExportModalOpened, setIsExportModalOpened] = useState(false)
  const [isEverythingSelected, setIsEverythingSelected] = useState(false)
  const [isTableLoaded, setIsTableLoaded] = useState(false)
  const { user } = useContext(UserContext)
  const [currentPageSize, setCurrentPageSize] = useState(pageSize)

  useEffect(() => {
    if (isLoadingStudy || !isTableLoaded) return

    refreshList()
  }, [reloadParticipantsTable])

  useEffect(() => {
    if (isLoadingStudy) return

    fetchParticipantsPage(0, sorter, search, filters)
  }, [isLoadingStudy, centers, search, filters, currentPageSize])

  useEffect(() => {
    setIsEverythingSelected(false)
  }, [centers, search, filters, sorter])

  useEffect(() => {
    setIsSearching(true)
  }, [search])

  useEffect(() => {
    if (!participants?.length || !isEverythingSelected) return

    setSelectedParticipants(participants.map((participant: TableRecord<Participant>) => participant.key))
    setIsEverythingSelected(true)
  }, [participants])

  const refreshList = () => {
    fetchParticipantsPage(currentPage - 1, sorter, search, filters)
    triggerReloadKpis()
  }

  const createParticipantTableRecords = (participants: Participant[]) => {
    return createTableRecords<Participant>(
      participants.map(participant => ({
        ...participant,
        centerAbbreviation: centers.find((c: CenterData) => c.id === participant.centerId)?.abbreviation,
        ...participant.variableAnswers[TableColumnVariableSource.RecruitmentSurvey],
        ...participant.variableAnswers[TableColumnVariableSource.SubjectDatabase]
      }))
    )
  }

  const fetchParticipantsPage = (
    page: number,
    sorter: ParticipantsSorter,
    search: string,
    filters: Record<string, string[]>
  ) => {
    setSelectedParticipants([])
    setIsFetchingParticipants(true)
    fetchParticipants(
      {
        studyId: study.id,
        options: {
          limit: currentPageSize,
          offset: page * currentPageSize,
          sorter,
          search,
          filters: {
            status: filters?.status
          }
        }
      },
      {
        onSuccess: ({ participants, countAll }) => {
          setParticipants(createParticipantTableRecords(participants))
          setCountAll(countAll)
          setAllParticipantsCount(countAll)
          setIsFetchingParticipants(false)
          setIsSearching(false)
          setCurrentPage(page + 1)
          setParticipantsTableOptions({ pageNumber: page, search, sorter })
          setIsTableLoaded(true)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingParticipants(false)
          setIsSearching(false)
        }
      }
    )
  }

  const onPageChange = (page: number) => {
    fetchParticipantsPage(page - 1, sorter, search, filters)
  }

  const onSorterChange = (tableSorter: { field: keyof Participant; order: SorterOrder }) => {
    const sorterChanged =
      Object.keys(tableSorter).length &&
      ((!sorter && tableSorter.order) || tableSorter.field !== sorter?.field || tableSorter.order !== sorter?.order)

    if (sorterChanged) {
      const newSorter = tableSorter.order
        ? {
            field: tableSorter.field,
            order: tableSorter.order === 'ascend' ? SorterOrder.Ascend : SorterOrder.Descend
          }
        : null
      fetchParticipantsPage(currentPage - 1, newSorter, search, filters)
      setSorter(newSorter)
    }
  }

  const onChangeStatus = (participant: Participant) => {
    setIsEverythingSelected(false)
    setSelectedParticipants([participant.id])
    setIsStatusModalOpened(true)
  }
  const columns = getListColumns({
    columnNames: {
      contactDetail: intlParticipants('column_name.contact_detail'),
      stage: intlParticipants('column_name.stage'),
      applied: intlParticipants('column_name.applied'),
      invited: intlParticipants('column_name.invited'),
      source: intlParticipants('column_name.source'),
      center: intlParticipants('column_name.center')
    },
    variables: (study?.tableVariables || []) as SourcedRecruitmentVariable[],
    allVariables,
    onChangeStatus
  })

  const onRowConfig = (participant: Participant) => ({
    onClick: (event: React.MouseEvent<HTMLElement>) => {
      const target = event.target as HTMLElement
      if (
        target.closest('ul')?.classList.value.includes('ant-dropdown-menu') ||
        rowClickNoPropagateClasses.some(c => target.closest('div')?.classList.value.includes(c)) ||
        target.matches('.datac-icon-box')
      ) {
        return
      }
      setParticipantToView(participant)
    }
  })

  const selectedCount = isEverythingSelected ? countAll : selectedParticipants.length

  const onStatusModalClose = () => {
    setIsStatusModalOpened(false)
    refreshList()
  }

  return (
    <>
      <div className="participants-table">
        <Collapse
          ghost
          items={[
            {
              key: 'filters',
              label: null,
              showArrow: false,
              children: (
                <DatacTableSearchAndFilters
                  onSearchChange={setSearch}
                  onFiltersChange={setFilters}
                  isSearching={isSearching}
                  searchAndFilterOptions={searchAndFilterOptions(intl)}
                  initialSearchValue={search}
                  initialFilterValues={filters}
                  collapsibleSearch
                  initialSearchSearchCollapsed={showFilters === 'search'}
                />
              )
            }
          ]}
          activeKey={showFilters ? 'filters' : null}
        />

        <DatacTable
          dataSource={participants}
          loading={isFetchingParticipants}
          columns={columns}
          selectedRows={selectedParticipants}
          setSelectedRows={setSelectedParticipants}
          setIsEverythingSelected={setIsEverythingSelected}
          onRow={onRowConfig}
          scroll={{ x: 1050 }}
          pagination={{
            current: currentPage,
            onChange: onPageChange,
            total: countAll,
            disabled: isFetchingParticipants,
            pageSize: currentPageSize
          }}
          setPageSize={setCurrentPageSize}
          onChange={(_, __, sorter) => onSorterChange(sorter as { field: keyof Participant; order: SorterOrder })}
        />
      </div>
      <RecruitmentProfileQuickView onClose={refreshList} />
      <ParticipantsExport
        onClose={() => setIsExportModalOpened(false)}
        isOpened={isExportModalOpened}
        studyId={study?.id}
        search={search}
        filters={filters}
        records={selectedParticipants}
        isEverythingSelected={isEverythingSelected}
      />
      <ParticipantsTableConfirmation
        onClose={onStatusModalClose}
        isOpened={isStatusModalOpened}
        search={search}
        filters={filters}
        records={participants.filter((c: Participant) => selectedParticipants.includes(c.id))}
        isEverythingSelected={isEverythingSelected}
      />
      <DatacBulkActionsBar
        selectedCount={selectedCount}
        onClose={() => {
          setSelectedParticipants([])
          setIsEverythingSelected(false)
        }}
        actions={[
          {
            label: intl('common.export'),
            icon: 'download',
            onClick: () => setIsExportModalOpened(true),
            hidden: !user.canDo(AclFeature.RecruitmentRecords)(AclAction.Export)
          },
          {
            label: intlParticipants('change_status'),
            icon: 'tag',
            onClick: () => setIsStatusModalOpened(true),
            hidden: !user.canDo(AclFeature.Recruitment)(AclAction.Edit)
          }
        ]}
      />
    </>
  )
}
