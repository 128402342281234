import { format } from 'date-fns'
import React from 'react'

import {
  Participant,
  ParticipantStatus,
  SimpleAnswer,
  SourcedRecruitmentVariable,
  downloadRecruitmentFileAnswer
} from '../../../../requests'
import { enumToOptions } from '../../../../utils'
import {
  ColumnFixedType,
  DatacAvatar,
  DatacColorStatusSelect,
  DatacDropdownMenu,
  DatacDropdownMenuOptions,
  DatacFileDownloadPreview,
  DatacTooltip,
  scopedIntlType
} from '../../../common'
import { AllRecruitmentVariables } from '../RecruitmentStudyDetailsStore'

export const renderContactDetail = (firstName: string, lastName: string, id: string, thumbnail: string) => (
  <div className="participants-table__name">
    <DatacAvatar firstName={firstName} lastName={lastName} size="xsmall" photoThumbnail={thumbnail} />
    <div>
      <div className="participants-table__name__full-name">
        {firstName} {lastName}
      </div>
      {id}
    </div>
  </div>
)

enum ParticipantListActionType {
  Status = 'status'
}

interface ActionCellProps {
  onChangeStatus: () => void
}

export const ActionCell: React.VFC<ActionCellProps> = ({ onChangeStatus }) => {
  const onClick = (item: string) => {
    switch (item) {
      case ParticipantListActionType.Status:
        onChangeStatus()
        break
      default:
        break
    }
  }

  const menuOptions: DatacDropdownMenuOptions<ParticipantListActionType> = [
    {
      type: ParticipantListActionType.Status,
      label: 'recruitment.study.participants.change_status',
      icon: 'tag'
    }
  ]

  return <DatacDropdownMenu options={menuOptions} onClick={onClick} />
}

const CustomCell: React.VFC<{ answer: SimpleAnswer }> = ({ answer }) => {
  if (!answer) return null

  return answer.isFile === false ? (
    <DatacTooltip title={answer.value}>
      <div className="subject-repository-dashboard__table__custom">{answer.value}</div>
    </DatacTooltip>
  ) : (
    <DatacFileDownloadPreview
      filename={answer.name}
      fileId={answer.uuid}
      downloadFile={downloadRecruitmentFileAnswer}
    />
  )
}

interface GetColumnsOptions {
  columnNames: {
    contactDetail: string
    stage: string
    applied: string
    center: string
    source: string
    invited: string
  }
  variables: SourcedRecruitmentVariable[]
  allVariables: AllRecruitmentVariables
  onChangeStatus: (participant: Participant) => void
}

export const getListColumns = ({ columnNames, variables, allVariables, onChangeStatus }: GetColumnsOptions) => {
  return [
    {
      title: columnNames.contactDetail,
      dataIndex: 'datacaptId',
      key: 'datacaptId',
      ellipsis: true,
      width: variables.length > 4 && '24rem',
      sorter: true,
      fixed: 'left' as ColumnFixedType,
      render: (datacaptId: string, { firstName, lastName, photoThumbnail }: Partial<Participant>) =>
        renderContactDetail(firstName, lastName, datacaptId, photoThumbnail)
    },
    {
      title: columnNames.stage,
      dataIndex: 'status',
      key: 'status',
      width: '23rem',
      sorter: true,
      fixed: 'left' as ColumnFixedType,
      render: (status: ParticipantStatus) => <DatacColorStatusSelect<ParticipantStatus> status={status} />
    },
    {
      title: columnNames.center,
      dataIndex: 'centerAbbreviation',
      key: 'centerAbbreviation',
      width: '16rem',
      ellipsis: true,
      sorter: true
    },
    {
      title: columnNames.source,
      dataIndex: 'source',
      key: 'source',
      width: '16rem',
      sorter: true
    },
    {
      title: columnNames.invited,
      dataIndex: 'invitedDate',
      key: 'invitedDate',
      width: '16rem',
      sorter: true,
      ellipsis: true,
      render: (_: Date) => '' // todo: format(invited, 'dd MMM yyyy')
    },
    {
      title: columnNames.applied,
      dataIndex: 'appliedDate',
      key: 'appliedDate',
      width: '16rem',
      sorter: true,
      ellipsis: true,
      render: (applied: Date) => format(applied, 'dd MMM yyyy')
    },
    ...variables?.map(variable => {
      const tooltip = (allVariables[variable.source] || []).find(v => v.variable === variable.variable)?.title
      return {
        title: tooltip ? <DatacTooltip title={tooltip}>{variable.variable}</DatacTooltip> : variable.variable,
        dataIndex: variable.variable,
        key: variable.variable,
        width: '16rem',
        render: (answer: SimpleAnswer) => <CustomCell answer={answer} />
      }
    }),
    {
      title: '',
      dataIndex: '',
      key: 'actions',
      render: (_: string, participant: Participant) => (
        <ActionCell onChangeStatus={() => onChangeStatus(participant)} />
      ),
      width: '5.8rem',
      fixed: 'right' as ColumnFixedType
    }
  ]
}

export const searchAndFilterOptions = (intl: scopedIntlType) => {
  const intlSearchBar = (id: string) => intl(`studies.inclusions.search_bar.${id}`)
  const intlStatus = (id: string) => intl(`status.${id}`)
  return {
    searchInputPlaceholder: intlSearchBar('search.placeholder'),
    filters: [
      {
        label: intlSearchBar('status.header'),
        name: 'status',
        options: enumToOptions(ParticipantStatus, intlStatus)
      }
    ]
  }
}
