import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { Form } from 'antd'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useCallback, useReducer } from 'react'

import { useScopedIntl } from '../../../../hooks'
import {
  AccountType,
  Answer,
  ConditionReferenceAnswer,
  DeleteAnswerResponseHandlers,
  DeleteFileResponseHandlers,
  DownloadFileAnswerOptions,
  DownloadFileResponseHandlers,
  Feature,
  FetchStructureResponseHandlers,
  FetchSubsectionResponseHandlers,
  FileAnswer,
  QuestionType,
  SaveAnswerResponseHandlers,
  SaveFileAnswerResponseHandlers,
  SaveFileResponseOptions,
  defaultMaxFileBytes,
  deleteRecruitmentAnswer,
  deleteRecruitmentFileAnswer,
  downloadRecruitmentFileAnswer,
  fetchAnswers,
  fetchConditionReferenceAnswers as fetchConditionReferenceAnswersRequest,
  fetchRecruitmentRecord,
  fetchRecruitmentStructure,
  fetchRecruitmentSubsection,
  saveRecruitmentAnswer,
  saveRecruitmentFileAnswer
} from '../../../../requests'
import { routes } from '../../../../routes'
import { questionIdWithMeasureId } from '../../../../utils'
import { UserContext } from '../../../auth'
import { DatacMessage } from '../../../common'
import {
  Fulfillment,
  FulfillmentAction,
  FulfillmentContext,
  fulfillmentInitialState,
  fulfillmentStateReducer,
  parseFulfillmentAnswers,
  parseReferenceAnswer
} from '../../../shared/Fulfillment'
import { useRecruitmentStudyDetailsStore } from '../RecruitmentStudyDetailsStore'

interface Props extends RouteComponentProps {
  recordId: string
  children?: React.ReactNode
}

const FulfillmentWrapper: React.FC<Props> = ({ recordId, children }) => {
  const intl = useScopedIntl('')
  const [fulfillmentState, fulfillmentDispatch] = useReducer(fulfillmentStateReducer, fulfillmentInitialState)
  const [formInstance] = Form.useForm()
  const { study } = useRecruitmentStudyDetailsStore()

  // studyId is id of recruitment study, regular study is not created yet
  const requestStudyIds = {
    recruitmentId: study.id,
    studyId: null as string
  }

  const fetchConditionReferenceAnswers = useCallback(() => {
    fetchConditionReferenceAnswersRequest(
      { ...requestStudyIds, recordId, feature: Feature.Recruitment },
      {
        onSuccess: (answers: ConditionReferenceAnswer[]) => {
          const conditionReferenceAnswers = answers.reduce(
            (acc, answer) => ({
              ...acc,
              [questionIdWithMeasureId(answer.blockId, answer.measureId)]: parseReferenceAnswer(
                answer.value,
                answer.type
              )
            }),
            {}
          )
          fulfillmentDispatch({ type: FulfillmentAction.SET_CONDITION_REFERENCE_ANSWERS, conditionReferenceAnswers })
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }, [study, recordId])

  const updateRecord = useCallback(
    (shouldUpdateSubsection = true) => {
      fetchRecruitmentRecord(
        { ...requestStudyIds, recordId },
        {
          onSuccess: record => fulfillmentDispatch({ type: FulfillmentAction.SET_RECORD, record }),
          onNotFound: () => navigate(routes.notFound(AccountType.User)),
          onRequestError: code => DatacMessage.genericError(intl, code)
        }
      )
      fetchConditionReferenceAnswers()

      if (shouldUpdateSubsection) {
        updateSubsection()
      }
    },
    [study, recordId, fulfillmentState.subsectionId]
  )

  const updateSubsection = useCallback(() => {
    fetchAnswers(
      {
        ...requestStudyIds,
        recordId,
        feature: Feature.Recruitment,
        subsectionId: fulfillmentState.subsectionId
      },
      {
        onSuccess: answers => {
          const parsedAnswers = parseFulfillmentAnswers(answers, '', formInstance)
          fulfillmentDispatch({
            type: FulfillmentAction.SET_ANSWERED_QUESTION_IDS,
            answeredQuestionIds: new Set(parsedAnswers.answered),
            prefilledQuestionIds: new Set(parsedAnswers.prefilled),
            sdvQuestionIds: new Set(parsedAnswers.sdv)
          })
          fulfillmentDispatch({
            type: FulfillmentAction.UPDATE_VALIDATION_MESSAGES,
            newValidationMessages: parsedAnswers.messages
          })
          formInstance.setFieldsValue(parsedAnswers.values)
        },
        onRequestError: () => {
          formInstance.setFieldsValue({})
          DatacMessage.error(
            intl('studies.fulfillment.subsection.error.fetch_answers.title'),
            intl('studies.fulfillment.subsection.error.fetch_answers.body')
          )
        }
      }
    )
  }, [study, recordId, fulfillmentState.subsectionId])

  const fetchStructure = (responseHandlers?: FetchStructureResponseHandlers) => {
    fetchRecruitmentStructure(requestStudyIds, responseHandlers)
  }

  const saveAnswer = useCallback(
    (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      options: { questionId: string; answer: any; reason?: string; nullAnswerType?: QuestionType },
      responseHandlers?: SaveAnswerResponseHandlers<Answer>
    ) => {
      saveRecruitmentAnswer(
        {
          ...requestStudyIds,
          recordId,
          questionId: options.questionId,
          answer: options.answer,
          reason: options.reason,
          nullAnswerType: options.nullAnswerType
        },
        responseHandlers
      )
    },
    [study, recordId]
  )

  const deleteAnswer = useCallback(
    (options: { questionId: string; reason?: string }, responseHandlers?: DeleteAnswerResponseHandlers) => {
      deleteRecruitmentAnswer(
        {
          ...requestStudyIds,
          recordId,
          questionId: options.questionId,
          reason: options.reason
        },
        responseHandlers
      )
    },
    [study, recordId]
  )

  const deleteFile = useCallback(
    (options: { fileId: string; reason?: string }, responseHandlers?: DeleteFileResponseHandlers) => {
      deleteRecruitmentFileAnswer({ fileId: options.fileId }, responseHandlers, {
        ...requestStudyIds,
        recordId,
        reason: options.reason
      })
    },
    [study, recordId]
  )

  const saveFile = useCallback(
    (options: SaveFileResponseOptions, responseHandlers?: SaveFileAnswerResponseHandlers<FileAnswer>) => {
      saveRecruitmentFileAnswer(
        {
          ...requestStudyIds,
          recordId,
          questionId: options.questionId,
          fileId: options.fileId,
          reason: options.reason
        },
        responseHandlers
      )
    },
    [study, recordId]
  )

  const downloadFile = useCallback(
    (options: DownloadFileAnswerOptions, responseHandlers?: DownloadFileResponseHandlers) => {
      downloadRecruitmentFileAnswer(options, responseHandlers)
    },
    [study, recordId]
  )

  const fetchSubsection = useCallback(
    (
      { sectionId, subsectionId }: { sectionId: string; subsectionId: string },
      responseHandlers?: FetchSubsectionResponseHandlers
    ) => {
      return fetchRecruitmentSubsection({ sectionId, subsectionId, ...requestStudyIds }, responseHandlers)
    },
    [study, recordId]
  )

  const navigateToSubsection = (sectionId: string, subsectionId: string) => {
    fulfillmentDispatch({
      type: FulfillmentAction.SET_SECTION_AND_SUBSECTION_IDS,
      sectionId,
      subsectionId
    })
  }

  return (
    <Form form={formInstance} name="fulfillment-form">
      <UserContext.Consumer>
        {({ user }) => {
          return (
            <FulfillmentContext.Provider
              value={{
                ...fulfillmentState,
                fulfillmentDispatch,
                formInstance,
                updateRecord,
                updateSubsection,
                fetchStructure,
                fetchSubsection,
                isEditingAvailable: true,
                navigateToSubsection,
                saveAnswer,
                deleteAnswer,
                deleteFile,
                saveFile,
                downloadFile,
                maxFileBytes: defaultMaxFileBytes,
                isInModal: true,
                isUnknownAnswerAvailable: false
              }}
            >
              {children}
            </FulfillmentContext.Provider>
          )
        }}
      </UserContext.Consumer>
    </Form>
  )
}

export const RecruitmentFulfillment: React.FC<Props> = ({ recordId }) => (
  <FulfillmentWrapper recordId={recordId}>
    <Fulfillment recordId={recordId} />
  </FulfillmentWrapper>
)
