import './VisitListTable.less'

import React, { useContext, useEffect, useMemo, useState } from 'react'

import { useScopedIntl } from '../../../../../../hooks'
import {
  AclFeature,
  BookedVisitStatus,
  BookedVisitsSorter,
  Schedule,
  ScheduleBookedVisit,
  SelectionKeys,
  SorterOrder,
  fetchCenters
} from '../../../../../../requests'
import { TableRecord } from '../../../../../../utils'
import { UserContext } from '../../../../../auth'
import {
  DatacBulkActionsBar,
  DatacMessage,
  DatacOption,
  DatacTable,
  DatacTableSearchAndFilters
} from '../../../../../common'
import { getListColumns, searchAndFilterOptions } from './VisitListTableConfig'
import { VisitListTableConfirmation } from './VisitListTableConfirmation'

interface Props {
  visits: ScheduleBookedVisit[]
  schedules: Schedule[]
  countAll: number
  currentPage: number
  isFetchingVisits: boolean
  filters: Record<string, string[]>
  search: string
  onPageChange: (page: number) => void
  onSorterChange: (sorter: BookedVisitsSorter) => void
  onFiltersChange: (filters: Record<string, string[]>) => void
  onSearchChange: (value: string) => void
  isEverythingSelected: boolean
  setIsEverythingSelected: (value: boolean) => void
  reloadTable: () => void
  showPayments: boolean
  pageSize: number
  setPageSize: (pageSize: number) => void
}

export const VisitListTable: React.FC<Props> = ({
  visits,
  schedules,
  countAll,
  isFetchingVisits,
  currentPage,
  search,
  filters,
  onPageChange,
  onSorterChange,
  onFiltersChange,
  onSearchChange,
  setIsEverythingSelected,
  isEverythingSelected,
  reloadTable,
  showPayments,
  pageSize,
  setPageSize
}) => {
  const intl = useScopedIntl('')
  const intlVisits = useScopedIntl('recruitment.study.schedules.visits')
  const [selectedRows, setSelectedRows] = useState<SelectionKeys>()
  const [tableRecords, setTableRecords] = useState<TableRecord<ScheduleBookedVisit>[]>(null)
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false)
  const [isUpdatingCompletedVisit, setIsUpdatingCompletedVisit] = useState(false)
  const [targetStatus, setTargetStatus] = useState<BookedVisitStatus>()
  const [isFetchingLocationOptions, setIsFetchingLocationOptions] = useState(false)
  const [locationOptions, setLocationOptions] = useState<DatacOption[]>([])
  const { user } = useContext(UserContext)

  useEffect(() => {
    setTableRecords(visits.map(createTableRecord))
  }, [visits])

  const createTableRecord = (visit: ScheduleBookedVisit) => ({ ...visit, key: visit.id.toString() })

  const visitOptions: DatacOption[] = useMemo(
    () =>
      schedules.reduce(
        (acc, curr) => [...acc, ...curr.visits.map(visit => ({ label: visit.title, value: visit.id }))],
        [] as DatacOption[]
      ),
    schedules
  )

  const onChangeStatus = (status: BookedVisitStatus, visit: ScheduleBookedVisit) => {
    setSelectedRows([visit.id])
    setTargetStatus(status)
    setIsConfirmModalOpened(true)
  }

  const onUpdateCompletedVisit = (visit: ScheduleBookedVisit) => {
    setSelectedRows([visit.id])
    setIsConfirmModalOpened(true)
    setIsUpdatingCompletedVisit(true)
  }

  const columns = getListColumns({
    columnNames: {
      date: intlVisits('column_name.date'),
      visitName: intlVisits('column_name.visit_name'),
      participant: intlVisits('column_name.participant'),
      location: intlVisits('column_name.location'),
      payment: intlVisits('column_name.payment'),
      status: intlVisits('column_name.status')
    },
    showPayments,
    onChangeStatus,
    onUpdateCompletedVisit
  })

  const fetchLocationOptions = () => {
    if (!user.canAccess(AclFeature.Centers)) return
    setIsFetchingLocationOptions(true)
    fetchCenters(
      { options: { sorter: { field: 'abbreviation', order: SorterOrder.Ascend } }, assignedToUser: true },
      {
        onSuccess: ({ centers }) => {
          setLocationOptions(centers.map(({ id, abbreviation }) => ({ value: id, label: abbreviation })))
          setIsFetchingLocationOptions(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingLocationOptions(false)
        }
      }
    )
  }

  const selectedCount = isEverythingSelected ? countAll : selectedRows?.length

  const onVisitConfirmationClose = () => {
    setIsConfirmModalOpened(false)
    setIsUpdatingCompletedVisit(false)
    setSelectedRows(null)
    setTargetStatus(null)
    reloadTable()
  }

  return (
    <div className="schedule-booking-visit-list-table">
      <DatacTableSearchAndFilters
        onSearchChange={onSearchChange}
        onFiltersChange={onFiltersChange}
        isSearching={isFetchingVisits || isFetchingLocationOptions}
        searchAndFilterOptions={searchAndFilterOptions(locationOptions, visitOptions, fetchLocationOptions, intl)}
        initialSearchValue={search}
        initialFilterValues={filters}
        collapsibleSearch
        buttonType="ghost"
      />
      <DatacTable
        dataSource={tableRecords}
        loading={isFetchingVisits}
        columns={columns}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        setIsEverythingSelected={setIsEverythingSelected}
        pagination={{
          current: currentPage,
          onChange: onPageChange,
          total: countAll,
          disabled: isFetchingVisits,
          pageSize
        }}
        setPageSize={setPageSize}
        onChange={(_, __, sorter) => onSorterChange(sorter as BookedVisitsSorter)}
      />
      <DatacBulkActionsBar
        selectedCount={selectedCount}
        onClose={() => {
          setSelectedRows([])
          setIsEverythingSelected(false)
        }}
        actions={[
          {
            label: intl('common.confirm'),
            icon: 'check',
            onClick: () => setIsConfirmModalOpened(true)
          }
        ]}
      />
      <VisitListTableConfirmation
        isOpened={isConfirmModalOpened}
        onClose={onVisitConfirmationClose}
        selectedVisits={tableRecords?.filter(record => selectedRows?.includes(record.key))}
        search={search}
        filters={filters}
        isEverythingSelected={isEverythingSelected}
        targetStatus={targetStatus}
        isUpdatingCompletedVisit={isUpdatingCompletedVisit}
      />
    </div>
  )
}
