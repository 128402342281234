import './VisitEdit.less'

import { Button, Checkbox, Form, Input, InputNumber } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../../../hooks'
import {
  AutomaticTimezone,
  CenterData,
  PaymentType,
  ScheduleVisit,
  ScheduleVisitAvailability,
  SorterOrder,
  UserConfigKey,
  fetchRecruitmentStudyUsers
} from '../../../../../../../requests'
import { maxTitleLengthLong, validateRequired } from '../../../../../../../validation'
import { UserContext } from '../../../../../../auth'
import { DatacFormItem, DatacIcon, DatacMessage, DatacOption, DatacSelect } from '../../../../../../common'
import { useRecruitmentStudyDetailsStore } from '../../../../RecruitmentStudyDetailsStore'
import { ChangeVisitWrapper } from '../AppointmentScheduleVisit'
import { AppointmentScheduleGeneralAvailability } from './GeneralAvailability'

interface AppointmentScheduleEditProps {
  visit: ScheduleVisit
  setVisit: (visit: ScheduleVisit) => void
  setInterval: (interval: number) => void
  setDuration: (duration: number) => void
  setCapacity: (capacity: number) => void
  setAvailability: (availability: ScheduleVisitAvailability[]) => void
  onGoBack: () => void
  missingPeriod: number
  isLastVisit: boolean
  readOnlyCapacity?: number
  visitNumber: number
  previousVisitFirstAvailabilityDate: Dayjs
}

export const AppointmentScheduleEdit: React.VFC<AppointmentScheduleEditProps> = ({
  visit,
  setVisit,
  setInterval,
  setDuration,
  setCapacity,
  setAvailability,
  onGoBack,
  missingPeriod,
  isLastVisit,
  readOnlyCapacity,
  visitNumber,
  previousVisitFirstAvailabilityDate
}) => {
  const intlVisit = useScopedIntl('recruitment.study.schedules.visit')
  const intl = useScopedIntl('')
  const [formInstance] = Form.useForm()
  const [centersOptions, setCentersOptions] = useState<DatacOption[]>([])
  const [isCustomDurationSelected, setIsCustomDurationSelected] = useState(false)
  const [isCustomIntervalSelected, setIsCustomIntervalSelected] = useState(false)
  const [isWindowChecked, setIsWindowChecked] = useState(false)
  const [isWindowCollapsed, setIsWindowCollapsed] = useState(true)
  const { study, centers } = useRecruitmentStudyDetailsStore()
  const [usersOptions, setUsersOptions] = useState<DatacOption[]>([])
  const [hadChanged, setHadChanged] = useState(false)
  const [currentTimezone, setCurrentTimezone] = useState(dayjs.tz.guess())
  const { user } = useContext(UserContext)

  useEffect(() => {
    if (!user || !centersOptions.length) return

    setHadChanged(false)
    setIsCustomDurationSelected(false)
    setIsCustomIntervalSelected(false)

    let duration: number | 'custom' = visit?.duration
    if (duration && !durationOptions.find(o => o.value === duration)) {
      setIsCustomDurationSelected(true)
      duration = 'custom'
    }

    let interval: number | 'custom' = visit?.interval
    if (interval && !intervalOptions.find(o => o.value === interval)) {
      setIsCustomIntervalSelected(true)
      interval = 'custom'
    }

    setIsWindowChecked(visit?.window != null)
    setIsWindowCollapsed(visit?.window == null)

    const defaultTimezone = user.timezone === AutomaticTimezone ? dayjs.tz.guess() : user.timezone
    const timezone = visit?.timezone || defaultTimezone
    dayjs.tz.setDefault(timezone)
    setCurrentTimezone(timezone)

    const availability = visit?.availability?.map((row, i) => ({
      ...row,
      isSameDay: visit.availability[i - 1]?.date.isSame(row.date, 'day')
    }))
    setAvailability(availability)

    const capacity = readOnlyCapacity || visit?.capacity || 1

    const title = visit?.title || intlVisit('default_title', { number: visitNumber })

    const savedCenters = user.getConfigValue<Record<string, string>>(UserConfigKey.LastScheduleCenters, {})
    const centerId =
      visit?.centerId || savedCenters[study.id] || (centersOptions.length === 1 && centersOptions[0]?.value)

    formInstance.setFieldsValue({
      title,
      duration,
      customDuration: duration === 'custom' ? visit?.duration : undefined,
      timezone,
      availability,
      coordinatorId: visit?.coordinatorId,
      payment: visit?.payment,
      centerId,
      window: visit?.window,
      interval: interval || null,
      customInterval: interval === 'custom' ? visit?.interval : undefined,
      capacity
    })

    setInterval(visit?.interval)
    setDuration(visit?.duration)
    setCapacity(capacity)

    if (visit) formInstance.validateFields()
  }, [visit, user, centersOptions])

  const onSelectCenter = (centerId: string) => {
    user.setConfigValue(UserConfigKey.LastScheduleCenters, {
      ...user.getConfigValue(UserConfigKey.LastScheduleCenters, {}),
      [study.id]: centerId
    })
  }

  useEffect(() => {
    setCentersOptions(
      centers
        .filter((c: CenterData) => study.centerIds.includes(c.id))
        .map((c: CenterData) => ({ value: c.id, label: c.abbreviation }))
    )

    fetchRecruitmentStudyUsers(
      {
        studyId: study.id,
        isFetchingAllUsersInStudyCenters: true,
        options: { sorter: { field: 'name', order: SorterOrder.Ascend } }
      },
      {
        onSuccess: ({ studyUsers }) => {
          setUsersOptions(
            studyUsers.map(({ id, name, email }) => ({ value: Number(id), label: name, sublabel: email }))
          )
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }, [])

  const onSubmit = (data: ScheduleVisit & { customDuration: number; customInterval: number }) => {
    setVisit({
      ...data,
      duration: isCustomDurationSelected ? data.customDuration : data.duration,
      interval: isCustomIntervalSelected ? data.customInterval : data.interval,
      window: data.window || null,
      timezone: currentTimezone
    })
  }

  const durationOptions = [
    { value: 15, label: intlVisit('duration.15') },
    { value: 30, label: intlVisit('duration.30') },
    { value: 45, label: intlVisit('duration.45') },
    { value: 60, label: intlVisit('duration.60') },
    { value: 90, label: intlVisit('duration.90') },
    { value: 120, label: intlVisit('duration.120') },
    { value: 'custom', label: intlVisit('duration.custom') }
  ]

  const onDurationChange = (value: string | number) => {
    setIsCustomDurationSelected(value === 'custom')
    if (typeof value !== 'string') setDuration(value)
  }

  const onCustomDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const duration = formInstance.getFieldValue('customDuration')
    const value = duration === '0' ? '' : e.target.value.replace(/\D/g, '')
    formInstance.setFieldsValue({ customDuration: value })
    setDuration(parseInt(value, 10))
  }

  const intervalOptions = [
    { value: null, label: intlVisit('interval.default') },
    { value: 5, label: intlVisit('duration.5') },
    { value: 10, label: intlVisit('duration.10') },
    { value: 15, label: intlVisit('duration.15') },
    { value: 20, label: intlVisit('duration.20') },
    { value: 30, label: intlVisit('duration.30') },
    { value: 45, label: intlVisit('duration.45') },
    { value: 60, label: intlVisit('duration.60') },
    { value: 'custom', label: intlVisit('duration.custom') }
  ]

  const onIntervalChange = (value: string | number) => {
    setIsCustomIntervalSelected(value === 'custom')
    if (typeof value !== 'string') setInterval(value)
  }

  const onCustomIntervalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const interval = formInstance.getFieldValue('customInterval')
    const value = interval === '0' ? '' : e.target.value.replace(/\D/g, '')
    formInstance.setFieldsValue({ customInterval: value })
    setInterval(parseInt(value, 10))
  }

  const onWindowChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '')
    formInstance.setFieldsValue({ window: value })
    formInstance.validateFields(['window'])
  }

  const onClickWindowChecked = (isChecked: boolean) => {
    setIsWindowChecked(isChecked)
    formInstance.setFieldsValue({ window: null })
  }

  return (
    <Form
      className="appointment-schedule-edit"
      form={formInstance}
      name="NewEventForm"
      onFinish={onSubmit}
      onValuesChange={(_, v) => {
        setHadChanged(true)
        setAvailability(v.availability)
      }}
    >
      <div className="appointment-schedule-edit__body">
        <DatacFormItem
          label={intlVisit('title.label')}
          name="title"
          validate={validateRequired(intl('common.required'))}
        >
          <Input size="large" placeholder={intlVisit('title.placeholder')} maxLength={maxTitleLengthLong} />
        </DatacFormItem>

        <DatacFormItem
          label={intlVisit('duration.label')}
          description={intlVisit('duration.description')}
          name="duration"
          validate={validateRequired(intl('common.required'))}
        >
          <DatacSelect
            size="large"
            placeholder={intlVisit('duration.placeholder')}
            options={durationOptions}
            onChange={onDurationChange}
            dropDownClassName="appointment-schedule-edit__body__duration-dropdown"
          />
        </DatacFormItem>
        {isCustomDurationSelected && (
          <DatacFormItem
            name="customDuration"
            validate={validateRequired(intl('common.required'))}
            className="appointment-schedule-edit__body__input-with-addon"
          >
            <Input
              size="large"
              placeholder={intlVisit('custom_duration.placeholder')}
              addonAfter={intlVisit('minutes')}
              onChange={onCustomDurationChange}
            />
          </DatacFormItem>
        )}

        <DatacFormItem
          label={intlVisit('interval.label')}
          description={intlVisit('interval.description')}
          name="interval"
          initialValue={null}
        >
          <DatacSelect
            size="large"
            options={intervalOptions}
            onChange={onIntervalChange}
            dropDownClassName="appointment-schedule-edit__body__interval-dropdown"
          />
        </DatacFormItem>
        {isCustomIntervalSelected && (
          <DatacFormItem name="customInterval" className="appointment-schedule-edit__body__input-with-addon">
            <Input
              size="large"
              placeholder={intlVisit('custom_interval.placeholder')}
              addonAfter={intlVisit('minutes')}
              onChange={onCustomIntervalChange}
            />
          </DatacFormItem>
        )}

        <DatacFormItem
          label={intlVisit('capacity.label')}
          name="capacity"
          validate={validateRequired(intl('common.required'))}
        >
          <InputNumber
            type="number"
            size="large"
            placeholder={intlVisit('capacity.placeholder')}
            onChange={n => setCapacity(n as number)}
            min={1}
            precision={0}
            disabled={!!readOnlyCapacity}
          />
        </DatacFormItem>

        <AppointmentScheduleGeneralAvailability
          formInstance={formInstance}
          setAvailability={setAvailability}
          duration={visit?.duration}
          missingPeriod={missingPeriod}
          currentTimezone={currentTimezone}
          setCurrentTimezone={setCurrentTimezone}
          previousVisitFirstAvailabilityDate={previousVisitFirstAvailabilityDate}
        />

        <DatacFormItem
          label={intlVisit('coordinator.label')}
          name="coordinatorId"
          validate={validateRequired(intl('common.required'))}
        >
          <DatacSelect
            showSearch
            options={usersOptions}
            placeholder={intlVisit('coordinator.placeholder')}
            allowBlank
          />
        </DatacFormItem>

        {study?.paymentType === PaymentType.Visit && (
          <DatacFormItem
            name="payment"
            label={intlVisit('payment.label')}
            className="appointment-schedule-edit__body__input-with-addon"
            validate={validateRequired(intl('common.required'))}
          >
            <Input min={0.01} step={0.01} placeholder={intlVisit('payment.placeholder')} addonAfter={study?.currency} />
          </DatacFormItem>
        )}

        <div className="appointment-schedule-edit__body__window">
          <div
            className="appointment-schedule-edit__body__window__expand"
            onClick={() => setIsWindowCollapsed(!isWindowCollapsed)}
          >
            <div>
              <div className="appointment-schedule-edit__body__window__expand__label">{intlVisit('window.label')}</div>
              <div className="appointment-schedule-edit__body__window__expand__description">
                {intlVisit('window.description')}
              </div>
            </div>
            <DatacIcon name={isWindowCollapsed ? 'chevronDown' : 'chevronUp'} type="transparent" />
          </div>
          {!isWindowCollapsed && (
            <>
              <Checkbox checked={isWindowChecked} onChange={e => onClickWindowChecked(e.target.checked)}>
                {intlVisit('window.checkbox')}
              </Checkbox>
              {isWindowChecked && (
                <DatacFormItem
                  name="window"
                  validate={validateRequired(intl('common.required'))}
                  className="appointment-schedule-edit__body__input-with-addon"
                >
                  <Input size="large" addonAfter={intlVisit('hours')} onChange={onWindowChange} />
                </DatacFormItem>
              )}
            </>
          )}
        </div>

        <DatacFormItem
          label={intlVisit('location.label')}
          name="centerId"
          validate={validateRequired(intl('common.required'))}
        >
          <DatacSelect
            showSearch
            options={centersOptions}
            placeholder={intlVisit('location.placeholder')}
            onChange={onSelectCenter}
          />
        </DatacFormItem>
      </div>

      <div className="appointment-schedule-edit__footer">
        <ChangeVisitWrapper showConfirm={hadChanged} onClick={onGoBack}>
          <Button type="default" size="large">
            {intl('common.back')}
          </Button>
        </ChangeVisitWrapper>
        <Button size="large" type="primary" htmlType="submit">
          {isLastVisit ? intl('common.save') : intlVisit('next')}
        </Button>
      </div>
    </Form>
  )
}
