import './RecruitmentStudySettingsQrCodes.less'

import { RouteComponentProps } from '@gatsbyjs/reach-router'
import React, { useEffect } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { RecruitmentQrCode, fetchRecruitmentStudy } from '../../../../../requests'
import { routes } from '../../../../../routes'
import { DatacMessage, DatacQrCodes } from '../../../../common'
import { useRecruitmentStudyDetailsStore } from '../../RecruitmentStudyDetailsStore'

export const RecruitmentStudySettingsQrCodes: React.FC<RouteComponentProps> = () => {
  const intl = useScopedIntl('')
  const { study, setStudy } = useRecruitmentStudyDetailsStore()

  useEffect(() => {
    if (!study) return

    // we need to refetch study when opening this component,
    // because new codes could be generated when general settings changed
    fetchRecruitmentStudy(
      { studyId: study.id },
      {
        onSuccess: study => setStudy(study),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }, [study?.id])

  return (
    <div className="recruitment-study-settings__content__body recruitment-study-settings__qr">
      <DatacQrCodes
        qrCodes={study?.qrCodes.map(({ center, token }: RecruitmentQrCode) => ({ name: center, token }))}
        urlSplitSeparator="/recruitment/"
        route={routes.recruitmentSignup}
      />
    </div>
  )
}
