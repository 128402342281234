import './ConfirmationPayments.less'

import { Input, InputNumber } from 'antd'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../../../../hooks'
import {
  BookedVisitStatus,
  ParticipantStatus,
  PaymentStatus,
  PaymentType,
  ScheduleBookedVisit
} from '../../../../../../../../requests'
import { maxCommentLength, validateRequired } from '../../../../../../../../validation'
import {
  DatacAvatar,
  DatacFormItem,
  DatacIcon,
  DatacInformationMessage,
  DatacRecordStatusTag,
  DatacTitle,
  DatacTooltip
} from '../../../../../../../common'

enum ConfirmPaymentType {
  alreadyDone,
  afterStudyEnd,
  cancelled,
  default
}

interface Props {
  theoreticalAmount: number
  applicationStatus: ParticipantStatus
  visitStatus: BookedVisitStatus
  paymentType: PaymentType
  setPaymentAmount: (amount?: number) => void
  paymentAmount: number
  currency: string
  paymentComment: string
  setPaymentComment: (comment: string) => void
  isAddingComment: boolean
  setIsAddingComment: (isAddingComment: boolean) => void
  paidVisits: ScheduleBookedVisit[]
}

export const ConfirmationPayments: React.FC<Props> = ({
  setPaymentAmount,
  paymentAmount,
  theoreticalAmount,
  paymentType,
  applicationStatus,
  visitStatus,
  currency,
  paymentComment,
  setPaymentComment,
  isAddingComment,
  setIsAddingComment,
  paidVisits
}) => {
  const intlConfirmation = useScopedIntl('recruitment.study.schedules.table.confirmation')
  const intl = useScopedIntl('')
  const [confirmationPaymentType, setConfirmationPaymentType] = useState(ConfirmPaymentType.default)
  const [editCancelledPayment, setEditCancelledPayment] = useState(false)
  const [isCommentTriggered, setIsCommentTriggered] = useState(isAddingComment)
  const [isCommentRequired, setIsCommentRequired] = useState(false)

  useEffect(() => {
    setIsCommentRequired(
      confirmationPaymentType === ConfirmPaymentType.cancelled
        ? paymentAmount !== 0
        : theoreticalAmount !== paymentAmount
    )
  }, [confirmationPaymentType, theoreticalAmount, paymentAmount])

  useEffect(() => {
    if (
      confirmationPaymentType === ConfirmPaymentType.alreadyDone ||
      (confirmationPaymentType === ConfirmPaymentType.afterStudyEnd && applicationStatus === ParticipantStatus.Enrolled)
    ) {
      setIsAddingComment(false)
      return
    }

    setIsAddingComment(isCommentRequired || isCommentTriggered)
  }, [confirmationPaymentType, applicationStatus, isCommentRequired, isCommentTriggered])

  useEffect(() => {
    setIsCommentTriggered(false)
    setEditCancelledPayment(false)

    if (paidVisits?.length) {
      setConfirmationPaymentType(ConfirmPaymentType.alreadyDone)
      setPaymentAmount(null)
      return
    }

    if (paymentType === PaymentType.Study && applicationStatus === ParticipantStatus.Enrolled) {
      setConfirmationPaymentType(ConfirmPaymentType.afterStudyEnd)
      setPaymentAmount(null)
      return
    }

    if (
      (paymentType === PaymentType.Study && applicationStatus === ParticipantStatus.Completed) ||
      (visitStatus === BookedVisitStatus.Completed && applicationStatus !== ParticipantStatus.Excluded)
    ) {
      setConfirmationPaymentType(ConfirmPaymentType.default)
      setPaymentAmount(theoreticalAmount)
      return
    }

    setConfirmationPaymentType(ConfirmPaymentType.cancelled)
    setPaymentAmount(0)
  }, [paymentType, applicationStatus, visitStatus, paidVisits])

  return (
    <div className="visit-list-table-confirmation-payments">
      <DatacTitle size="medium">{intlConfirmation('payments')}</DatacTitle>
      {confirmationPaymentType === ConfirmPaymentType.alreadyDone && (
        <>
          <DatacInformationMessage type="info" message={intlConfirmation('payment_already_done')} />
          {paidVisits.map(visit => (
            <div key={visit.id} className="visit-list-table-confirmation-payments__payment">
              <DatacAvatar
                photoThumbnail={visit.participantPhotoThumbnail}
                fullName={visit.participantName}
                icon="dollar"
              />
              <div className="visit-list-table-confirmation-payments__payment__info">
                <div>{visit.participantName}</div>
                <div>
                  <span>
                    {visit.paymentAmount} {visit.paymentCurrency}
                  </span>
                  {visit.paymentCreatedAt?.format('YYYY-MM-DD HH:mm')}
                </div>
              </div>
              <div>
                <DatacTooltip placement="top" title={visit.paymentDate?.format('YYYY-MM-DD HH:mm')} trigger="hover">
                  <div>
                    <DatacRecordStatusTag status={visit.paymentStatus} coloredBackground />
                  </div>
                </DatacTooltip>
              </div>
            </div>
          ))}
        </>
      )}
      {confirmationPaymentType === ConfirmPaymentType.afterStudyEnd && (
        <DatacInformationMessage type="info" message={intlConfirmation('payment_after_study_end')} />
      )}
      {confirmationPaymentType === ConfirmPaymentType.cancelled && (
        <div className="visit-list-table-confirmation-payments__amount">
          {editCancelledPayment ? (
            <>
              <InputNumber
                min={0}
                step={0.01}
                value={paymentAmount}
                onChange={amount => setPaymentAmount(Number(amount))}
              />
              <Input disabled defaultValue={currency} />
              <span>
                {intlConfirmation('planned_payments')}: {theoreticalAmount} {currency}
              </span>
              <DatacRecordStatusTag status={PaymentStatus.Approved} coloredBackground noDot />
            </>
          ) : (
            <div className="visit-list-table-confirmation-payments__amount__cancelled">
              <span>
                {theoreticalAmount} {currency}
              </span>
              <DatacRecordStatusTag status={PaymentStatus.Cancelled} coloredBackground noDot />
              <DatacIcon name="edit" onClick={() => setEditCancelledPayment(true)} />
            </div>
          )}
        </div>
      )}
      {confirmationPaymentType === ConfirmPaymentType.default && (
        <div className="visit-list-table-confirmation-payments__amount">
          <InputNumber
            min={0}
            step={0.01}
            value={paymentAmount != null ? paymentAmount : theoreticalAmount}
            onChange={amount => setPaymentAmount(Number(amount))}
          />
          <Input disabled defaultValue={currency} />
          <span>
            {intlConfirmation('planned_payments')}: {theoreticalAmount} {currency}
          </span>
          <DatacRecordStatusTag status={PaymentStatus.Approved} coloredBackground />
        </div>
      )}
      {isAddingComment ? (
        <div className="visit-list-table-confirmation-payments__comment">
          <DatacFormItem
            name="comment"
            label={intlConfirmation('payment_comment')}
            validate={validateRequired(intl('common.required'))}
          >
            <Input
              type="large"
              maxLength={maxCommentLength}
              value={paymentComment}
              onChange={e => setPaymentComment(e.currentTarget.value)}
            />
          </DatacFormItem>
          {!isCommentRequired && <DatacIcon name="x" type="red" onClick={() => setIsCommentTriggered(false)} />}
        </div>
      ) : (
        ![ConfirmPaymentType.afterStudyEnd, ConfirmPaymentType.alreadyDone].includes(confirmationPaymentType) && (
          <div
            className="visit-list-table-confirmation-payments__add-comment"
            onClick={() => setIsCommentTriggered(true)}
            role="button"
            tabIndex={0}
          >
            <DatacIcon name="plus" type="transparent" />
            {intlConfirmation('add_payment_comment')}
          </div>
        )
      )}
    </div>
  )
}
