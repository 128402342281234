import './RecruitmentProfileSurvey.less'

import { Button } from 'antd'
import React, { useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { DatacBox, DatacIcon, DatacModal } from '../../../../common'
import { RecruitmentFulfillment } from '../../RecruitmentFulfillment'
import { useRecruitmentStudyDetailsStore } from '../../RecruitmentStudyDetailsStore'

export const RecruitmentProfileSurvey: React.FC = () => {
  const intlProfile = useScopedIntl('recruitment.study.profile')
  const { participantToView } = useRecruitmentStudyDetailsStore()
  const [isShowingFulfillment, setIsShowingFulfillment] = useState(false)

  //  TODO: uncomment when progress is available
  //  const renderProgress = candidateToView?.progress !== null && (
  //    <Progress
  //      status="normal"
  //      className={candidateToView.progress === 100 ? 'ant-progress-completed' : null}
  //      percent={candidateToView.progress}
  //    />
  //  )

  return (
    !!participantToView && (
      <DatacBox className="recruitment-profile-survey">
        <div className="recruitment-profile-survey__title">
          <DatacIcon raw name="checkCircle" /> {intlProfile('survey')}
        </div>
        <div className="recruitment-profile-survey__body">
          {
            // renderProgress TODO: uncomment when progress is available
          }
          <Button
            type="text"
            className="recruitment-profile-header__link"
            onClick={() => setIsShowingFulfillment(true)}
          >
            {intlProfile('results')}
          </Button>
        </div>
        {isShowingFulfillment && (
          <DatacModal
            title={participantToView?.datacaptId}
            isOpened
            onClose={() => setIsShowingFulfillment(null)}
            className="epro-records-table__fulfillment-modal"
            noClose
            noSubmit
            destroyOnClose
          >
            <RecruitmentFulfillment recordId={participantToView.id} />
          </DatacModal>
        )}
      </DatacBox>
    )
  )
}
