import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { Button } from 'antd'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import {
  CenterData,
  SorterOrder,
  UserData,
  UsersSorter,
  fetchRecruitmentStudyUsers,
  fetchRoles,
  fetchUsersNotInRecruitmentStudy,
  inviteUsersToRecruitmentStudy,
  removeRecruitmentStudyUser
} from '../../../../../requests'
import { DatacIcon, DatacMessage, DatacModal, DatacOption } from '../../../../common'
import { UsersTable, rolesDataToOptions } from '../../../../shared/UsersTable'
import { useRecruitmentStudyDetailsStore } from '../../RecruitmentStudyDetailsStore'
import { StudyUsersEditModal } from './StudyUsersEditModal'
import { InviteFormData, StudyUsersInviteModal } from './StudyUsersInviteModal'

interface Props extends RouteComponentProps {
  studyId?: string
}

const defaultSorter: UsersSorter = { field: 'name', order: SorterOrder.Ascend }

const pageSize = 25
export const RecruitmentStudySettingsUsers: React.FC<Props> = ({ studyId }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [studyUsers, setStudyUsers] = useState<UserData[]>(null)
  const [allUsers, setAllUsers] = useState<UserData[]>([])
  const [userToEdit, setUserToEdit] = useState<UserData>(null)
  const [allCount, setAllCount] = useState(0)
  const [isFetchingUsers, setIsFetchingUsers] = useState(false)
  const [sorter, setSorter] = useState<UsersSorter>(null)
  const [filters, setFilters] = useState<Record<string, string[]>>()
  const [search, setSearch] = useState<string>(null)
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [userToDeleteId, setUserToDeleteId] = useState<string>(null)
  const [isRemovingUser, setIsRemovingUser] = useState(false)
  const intlUsers = useScopedIntl('studies.users')
  const intl = useScopedIntl('')
  const { study, centers } = useRecruitmentStudyDetailsStore()
  const [centersOptions, setCentersOptions] = useState<DatacOption[]>([])
  const [usedRoles, setUsedRoles] = useState<DatacOption[]>([])
  const [currentPageSize, setCurrentPageSize] = useState(pageSize)

  const fetchAvailableRoles = () => {
    return fetchRoles(
      {},
      {
        onSuccess: ({ roles }) => {
          setUsedRoles(rolesDataToOptions(roles, true))
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  useEffect(() => {
    if (!study || !centers) return

    fetchAllUsers()
    fetchAvailableRoles()
    setCentersOptions(
      centers
        .filter((c: CenterData) => study.centerIds.includes(c.id))
        .map((c: CenterData) => ({
          value: c.id,
          label: c.abbreviation
        }))
    )
  }, [study, centers])

  useEffect(() => {
    fetchStudyUsersPage(0, sorter, search, filters)
  }, [search, filters, currentPageSize])

  const onPageChange = (page: number) => {
    fetchStudyUsersPage(page - 1, sorter, search, filters)
  }

  const fetchStudyUsersPage = (
    page: number,
    sorter: UsersSorter,
    search: string,
    filters: Record<string, string[]>
  ) => {
    setIsFetchingUsers(true)
    return fetchRecruitmentStudyUsers(
      {
        studyId,
        options: {
          limit: currentPageSize,
          offset: page * currentPageSize,
          sorter: sorter || defaultSorter,
          search,
          filters
        }
      },
      {
        onSuccess: ({ studyUsers, allStudyUsersCount }) => {
          setStudyUsers(studyUsers)
          setAllCount(allStudyUsersCount)
          setIsFetchingUsers(false)
          setCurrentPage(page + 1)
        },
        onRequestError: code => {
          setIsFetchingUsers(false)
          DatacMessage.genericError(intl, code)
        }
      }
    )
  }

  const onSorterChange = (tableSorter: { field: keyof UserData; order: 'ascend' | 'descend' }) => {
    const sorterChanged =
      (!sorter && tableSorter.order) ||
      (sorter && (tableSorter.field !== sorter?.field || tableSorter.order !== sorter?.order))
    if (sorterChanged) {
      const newSorter = tableSorter.order
        ? {
            field: tableSorter.field,
            order: tableSorter.order === 'ascend' ? SorterOrder.Ascend : SorterOrder.Descend
          }
        : null
      fetchStudyUsersPage(currentPage - 1, newSorter, search, filters)
      setSorter(newSorter)
    }
  }

  const onStudyUserDelete = (studyUserId: string) => {
    setIsRemovingUser(true)
    removeRecruitmentStudyUser(
      { studyId, userId: studyUserId },
      {
        onSuccess: () => {
          setIsRemovingUser(false)
          setUserToDeleteId(null)
          refreshUsersList()
        },
        onError: () => setIsRemovingUser(false),
        onRequestError: code => DatacMessage.genericError(intl, code),
        onCannotRemoveAdmin: () =>
          DatacMessage.error(
            intl('studies.users.error.remove_admin.title'),
            intl('studies.users.error.remove_admin.description')
          )
      }
    )
  }

  const fetchAllUsers = () => {
    fetchUsersNotInRecruitmentStudy(
      { studyId },
      {
        onSuccess: setAllUsers,
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const sendInvitations = (data: InviteFormData) => {
    inviteUsersToRecruitmentStudy(
      { studyId, ...data },
      {
        onSuccess: () => {
          setIsModalOpened(false)
          refreshUsersList()
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const onFiltersChange = (filters: Record<string, string[]>) => {
    setFilters(filters)
  }

  const refreshUsersList = () => {
    fetchAllUsers()
    fetchStudyUsersPage(currentPage - 1, sorter, search, filters)
  }

  const tableOptions = (
    <div className="recruitment-study-settings-users__options">
      <Button type="default" onClick={() => setIsModalOpened(true)}>
        <DatacIcon type="grey" name="plus" raw /> {intlUsers('invite_button')}
      </Button>
    </div>
  )

  return (
    <div className="recruitment-study-settings-users">
      {!!studyUsers && (
        <UsersTable
          onDelete={user => setUserToDeleteId(user.id)}
          users={studyUsers.map(user => ({
            ...user,
            centers: centers.filter((c: CenterData) => user.centers.map(uc => uc.id).includes(c.id))
          }))}
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalCount={allCount}
          pageSize={currentPageSize}
          setPageSize={setCurrentPageSize}
          disabled={isFetchingUsers}
          onSorterChange={onSorterChange}
          isUserDeletable={() => true}
          isUserEditable={() => true}
          onEdit={setUserToEdit}
          isModuleTable
          onFiltersChange={onFiltersChange}
          filters={filters}
          onSearchChange={setSearch}
          search={search}
          centers={centers}
          options={tableOptions}
          usedRoles={usedRoles}
        />
      )}
      <StudyUsersInviteModal
        isOpened={isModalOpened}
        onClose={() => setIsModalOpened(false)}
        allUsers={allUsers}
        onSubmit={sendInvitations}
        centersOptions={centersOptions}
      />
      <DatacModal
        title={intlUsers('remove_modal.title')}
        onClose={() => setUserToDeleteId(null)}
        isOpened={!!userToDeleteId}
        onSubmit={() => onStudyUserDelete(userToDeleteId)}
        submitLabel={intlUsers('remove_modal.submit')}
        loading={isRemovingUser}
      >
        {intlUsers('remove_modal.message')}
      </DatacModal>
      <StudyUsersEditModal
        isOpened={!!userToEdit}
        userToEdit={userToEdit}
        onClose={() => setUserToEdit(null)}
        onUserEdited={refreshUsersList}
        centersOptions={centersOptions}
      />
    </div>
  )
}
