import './ConfirmationPayments.less'

import { Input, InputNumber } from 'antd'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../../hooks'
import { ParticipantStatus, PaymentStatus } from '../../../../../../requests'
import { DatacIcon, DatacRecordStatusTag, DatacTitle } from '../../../../../common'

enum ConfirmPaymentType {
  cancelled,
  default
}

interface Props {
  theoreticalAmount: number
  applicationStatus: ParticipantStatus
  setPaymentAmount: (amount?: number) => void
  paymentAmount: number
  currency: string
}

export const ConfirmationPayments: React.FC<Props> = ({
  setPaymentAmount,
  paymentAmount,
  theoreticalAmount,
  applicationStatus,
  currency
}) => {
  const intlConfirmation = useScopedIntl('recruitment.study.schedules.table.confirmation')
  const [confirmationPaymentType, setConfirmationPaymentType] = useState(ConfirmPaymentType.default)
  const [editCancelledPayment, setEditCancelledPayment] = useState(!!paymentAmount)

  useEffect(() => {
    if (applicationStatus === ParticipantStatus.Excluded) {
      setConfirmationPaymentType(ConfirmPaymentType.cancelled)
      setPaymentAmount(paymentAmount || 0)
      return
    }

    setConfirmationPaymentType(ConfirmPaymentType.default)
    setPaymentAmount(theoreticalAmount)
  }, [])

  return (
    <div className="candidates-table-confirmation-payments">
      <DatacTitle size="medium">{intlConfirmation('payments')}</DatacTitle>
      {confirmationPaymentType === ConfirmPaymentType.cancelled && (
        <div className="candidates-table-confirmation-payments__amount">
          {editCancelledPayment ? (
            <>
              <InputNumber
                min={0}
                step={0.01}
                defaultValue={paymentAmount}
                onChange={amount => setPaymentAmount(Number(amount))}
              />
              <Input disabled defaultValue={currency} />
              <span>
                {intlConfirmation('planned_payments')}: {theoreticalAmount} {currency}
              </span>
              <DatacRecordStatusTag status={PaymentStatus.Approved} coloredBackground noDot />
            </>
          ) : (
            <div className="candidates-table-confirmation-payments__amount__cancelled">
              <span>
                {theoreticalAmount} {currency}
              </span>
              <DatacRecordStatusTag status={PaymentStatus.Cancelled} coloredBackground noDot />
              <DatacIcon name="edit" onClick={() => setEditCancelledPayment(true)} />
            </div>
          )}
        </div>
      )}
      {confirmationPaymentType === ConfirmPaymentType.default && (
        <div className="candidates-table-confirmation-payments__amount">
          <InputNumber
            min={0}
            step={0.01}
            defaultValue={paymentAmount != null ? paymentAmount : theoreticalAmount}
            onChange={amount => setPaymentAmount(Number(amount))}
          />
          <Input disabled defaultValue={currency} />
          <span>
            {intlConfirmation('planned_payments')}: {theoreticalAmount} {currency}
          </span>
          <DatacRecordStatusTag status={PaymentStatus.Approved} coloredBackground />
        </div>
      )}
    </div>
  )
}
