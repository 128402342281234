import { RouteComponentProps, useMatch } from '@gatsbyjs/reach-router'
import React from 'react'

import { routes } from '../../../../routes'
import { BuilderElements } from '../../../shared/Builder'
import { useRecruitmentStudyDetailsStore } from '../RecruitmentStudyDetailsStore'
import { RecruitmentStudyBuilder } from './RecruitmentStudyBuilder'

export const RecruitmentStudyBuilderContent: React.FC<RouteComponentProps> = ({ location }) => {
  const match = useMatch('section/:sectionId')
  const { study } = useRecruitmentStudyDetailsStore()

  const showInBuilder = () => {
    if (!match) return BuilderElements.BuilderEntry

    return BuilderElements.Structure
  }

  return (
    !!study && (
      <RecruitmentStudyBuilder
        studyId={study.id}
        sectionId={match?.sectionId}
        location={location}
        backRoute={routes.recruitmentStudySettings(study.id)}
        showInBuilder={showInBuilder()}
        title={study.reference}
      />
    )
  )
}
